<template>
  <div :class="{overflowHidden: showModal}">
    <sub-head v-bind:subTitle="content['게임 소개']"></sub-head>
    <div class="page_padding">
      <div class="paddingTB">
        <div class="text-center">
          <span class="fontBold font41 text333"
            >해피테이블 <span class="textPink fontBold">게임소개</span></span
          >
        </div>
        <div class="verticalSpacer-half"></div>
        <div class="text333">
          <ul>
            <li
              v-on:click="filter_game_kind('all')"
              :class="{active: game_kind == 'all'}"
            >
              전체
            </li>
            <li
              v-on:click="filter_game_kind('협동')"
              :class="{active: game_kind == '협동'}"
            >
              협동
            </li>
            <li
              v-on:click="filter_game_kind('경쟁')"
              :class="{active: game_kind == '경쟁'}"
            >
              경쟁
            </li>
            <li
              v-on:click="filter_game_kind('힐링')"
              :class="{active: game_kind == '힐링'}"
            >
              힐링
            </li>
          </ul>
        </div>
        <div
          class="game_info d-flex justify-content-between"
          style="padding:1rem 0"
        >
          <modal
            v-show="showModal"
            v-on:close="closeModal"
            :item="modalInfo"
            ref="gameModal"
          >
            <template>
              <div slot="header">
                <div class="d-flex modal_head">
                  <div class="modal_imgWrap flex-set width45">
                    <image-component
                      :is_jpg="false"
                      :name="modalInfo.game"
                      path="icons"
                      class="borderRadius bgBackground"
                    ></image-component>
                  </div>
                  <div class="modal_gameInfo text-left paddingM width55">
                    <div class="game_name">
                      <span class="font30 fontExtraBold text333">{{
                        modalInfo.game_eng
                      }}</span>
                    </div>

                    <div class="paddingTB1">
                      <span class="font24 text333">{{
                        modalInfo.game_kor
                      }}</span>
                    </div>
                    <div
                      class="textWhite d-flex borderRadius padding0-8"
                      style="font-size:0.8rem;"
                    >
                      <div :class="{[modalInfo.game_kind]: true}" class="kind">
                        <span>{{ modalInfo.game_kind }}</span>
                      </div>
                      <div class="player">
                        <span>{{ modalInfo.player }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div slot="screenshot">
                <div
                  v-for="(item, index) in 2"
                  :key="index"
                  class="screenshotWrap"
                >
                  <image-component
                    :is_jpg="true"
                    :name="modalInfo.game + (index + 1)"
                    path="screenshots"
                  ></image-component>
                </div>
              </div>
            </template>
          </modal>
          <div
            v-for="(item, info) in filtering_game(game_kind, is_all)"
            :key="info"
            class="item"
            v-on:click="game_detail(item)"
          >
            <div @click="handleOpen" class="word_break">
              <div class="content-padding">
                <div class="imgWrap">
                  <image-component
                    :is_jpg="false"
                    :name="item.game"
                    path="icons"
                  ></image-component>
                </div>
                <div class="flex-set game_block">
                  <span class="font20 fontExtraBold text333">{{
                    item.game_eng
                  }}</span>
                </div>
                <div class="flex-set game_kor text333">
                  <span>{{ item.game_kor }}</span>
                </div>
                <div
                  class="textWhite d-flex borderRadius justify-content-center paddingTB1 game_property"
                >
                  <div :class="{[item.game_kind]: true}" class="kind">
                    <span class="font14">{{ item.game_kind }}</span>
                  </div>
                  <div class="player">
                    <span class="font14">{{ item.player }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="item hidden"
            v-for="item in game_count()"
            :key="item"
          ></div>
        </div>
        <button
          href="#"
          class="show_more_btn"
          v-on:click="is_show()"
          v-show="is_more()"
        >
          {{ button_value }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import subTopContents from "../../assets/source/json/sub_top.json";
import gameContents from "../../assets/source/json/game_info.json";
import subHead from "../../components/sub_head.vue";
import modal from "../../components/modal/modal.vue";
import ImageComponent from "../../components/imageComponent.vue";
export default {
  components: {subHead, modal, ImageComponent},
  props: ["game_name"],
  data() {
    return {
      content: subTopContents,
      all_game_info: gameContents,
      game_info: gameContents,
      is_true: false,
      game_kind: "all",
      show: false,
      is_all: "",
      button_value: "더보기",
      is_moreshow: true,
      showing_count: 12,
      showModal: false,
      modalInfo: {
        game: "GAME_BALLOON",
        game_eng: "",
        game_kor: "",
        game_kind: "",
        player: "",
        text: "",
      },
    };
  },
  methods: {
    game_detail(item) {
      this.is_true = true;
      this.showModal = true;
      this.modalInfo.game = item.game;
      this.modalInfo.game_eng = item.game_eng;
      this.modalInfo.game_kor = item.game_kor;
      this.modalInfo.game_kind = item.game_kind;
      this.modalInfo.player = item.player;
      this.modalInfo.text = item.text;
      return this.modalInfo;
    },
    game_count() {
      var game_count = Object.keys(this.game_info).length;
      var empty_box = [];
      if (game_count % 4 != 0) {
        game_count = 4 - (game_count % 4);
      }
      for (var i = 0; i < game_count; i++) {
        empty_box[i] = "empty" + i;
      }
      return empty_box;
    },
    is_show() {
      if (this.show == false) {
        // 전체 보기
        this.show = true;
        this.is_all = "all";
        this.button_value = "접기";
      } else {
        // 상위만 보기
        this.show = false;
        this.is_all = "";
        this.button_value = "더보기";
      }
    },
    filtering_game: function(kind, more) {
      var count = this.showing_count;
      // 필터링 후 더보기 버튼 유무 판단
      this.is_more();

      // 게임 전체일 때
      if (kind == "all") {
        // 더보기 눌렀으면
        if (more == "all") {
          return this.game_info;
        } else {
          return this.game_info.filter(function(content, index) {
            return index < count;
          });
        }
      }
      // 게임 종류 필터 눌렀을 때
      else {
        if (more == "all") {
          return this.game_info;
        } else {
          return this.game_info.filter(function(content, index) {
            return content.game_kind == kind && index < count;
          });
        }
      }
    },
    filter_game_kind(kind) {
      this.game_kind = kind;
      this.game_info = [];
      if (this.game_kind == "all") {
        this.game_info = this.all_game_info;
      } else {
        for (var i = 0; i < this.all_game_info.length; i++) {
          if (this.all_game_info[i].game_kind == this.game_kind) {
            this.game_info.push(this.all_game_info[i]);
          }
        }
      }
      this.show = false;
      this.is_all = "";
      this.button_value = "더보기";
    },
    is_more() {
      var count = this.showing_count;
      if (this.game_info.length <= count) {
        this.is_moreshow = false;
      } else {
        this.is_moreshow = true;
      }
      return this.is_moreshow;
    },
    handleOpen() {
      document.querySelector("body").style.overflow = "hidden";
      this.showModal = true;
    },
    closeModal() {
      document.getElementById("gameModal").scrollTop = 0;
      this.showModal = false;
      document.querySelector("body").style.overflowY = "scroll";
    },
    openSlideModal(game_name) {
      for (var i = 0; i < this.all_game_info.length; i++) {
        if (game_name == this.all_game_info[i].game) {
          this.game_detail(this.all_game_info[i]);
        }
      }
    },
  },
  mounted() {
    if (this.game_name != "") {
      this.openSlideModal(this.game_name);
    }
  },
};
</script>

<style scoped>
ul li {
  list-style: none;
  border-right: 1px solid #e6e6e6;
  width: 25%;
  padding: 1.5rem 0;
  background-color: #f4f4f4;
  color: #333;
  font-size: 1.1rem;
}
ul li:last-child {
  border-right: 0px solid gray;
}

ul {
  display: flex;
  width: 100%;
  /* border: 1px solid gray; */
}
.padding0-8 {
  padding: 0.8rem 0;
}
.imgWrap img {
  width: 100%;
}
.modal_imgWrap {
  max-width: 180px;
  max-height: 180px;
}
.modal_imgWrap img {
  border-radius: 0 !important;
}
.game_info {
  flex-wrap: wrap;
}
.game_info .item {
  width: 24%;
}
.item {
  border: 1px solid #d4d3d3;
  border-radius: 18px;
  margin: 1rem 0;
}
.item:hover {
  cursor: pointer;
  border: 1px solid #db3960;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.item.hidden {
  border: none;
  box-shadow: none;
  cursor: unset;
  margin: 0;
}
.active {
  background-color: #db3960;
  border-color: #db3960 !important;
  color: white;
}

.kind,
.player {
  padding: 0.2rem 1rem;
  border-radius: 10px;
}
.kind {
  margin-right: 10px;
}
.player {
  background-color: #2b2e35;
}
.bgBackground {
  background-color: #e6e6e6;
}
.borderRadius {
  border-radius: 28px;
}
.modal_gameInfo {
  padding: 0.5rem 0 1rem 2rem;
}
.modal_gameInfo .kind {
  margin-right: 10px;
}
.screenshotWrap:first-child {
  margin: 30px 0;
}
.content-padding {
  padding: 1rem 20%;
}
.game_block {
  padding: 0.5rem 0 0.5rem 0;
  height: 4rem;
}
.game_kor {
  height: 4rem;
}
.overflowAuto {
  overflow: auto;
}
@media (min-width: 1281px) and (max-width: 1450px) {
  .modal_gameInfo .font30 {
    font-size: 26px;
  }
  .modal_gameInfo .font24 {
    font-size: 18px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .game_info .item {
    width: 32%;
  }
  .game_block {
    height: 3rem;
  }
  .game_kor {
    height: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .game_info .item {
    width: 32%;
  }
  .content-padding {
    padding: 1rem 10%;
  }
  .game_block {
    height: 2rem;
  }
  .game_kor {
    height: 2rem;
  }
  .modal_imgWrap[data-v-7280d876] {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 767px) {
  ul li {
    padding: 1.2rem 0;
    font-size: 1rem;
  }
  .game_info .item {
    width: 48%;
  }
  .content-padding {
    padding: 1rem 10%;
  }
  .game_block {
    padding: 0.5rem 0 0.25rem 0;
    height: 2rem;
  }
  .game_kor {
    height: 2rem;
  }

  .modal_imgWrap {
    max-width: 120px;
    max-height: 120px;
    /* width: 45%;
    height: 45%; */
  }
  .modal_gameInfo {
    padding: 0.5rem 0 1rem 1rem;
  }
  .modal-container .paddingTB1 {
    padding: 0.2rem 0;
  }
  .kind,
  .player {
    padding: 0.3rem 1rem;
  }
  .screenshotWrap:first-child {
    margin: 15px 0;
  }
}
/* @media (min-width: 500px) and (max-width: 599px) { */
@media (max-width: 599px) {
  .modal_gameInfo {
    padding: 0.5rem 0 1rem 1rem;
  }
  .d-flex.modal_head {
    flex-direction: column;
  }
  .modal_gameInfo.width55 {
    width: 100%;
    padding: 1rem 0;
  }
  .modal_imgWrap.width45 {
    width: 100%;
    margin: 0 auto;
  }
  .game_name {
    margin: 1rem 0;
  }
  .modal_gameInfo {
    text-align: center;
  }
  .textWhite.padding0-8 {
    justify-content: center;
  }
}
@media (max-width: 499px) {
  .modal_gameInfo {
    padding: 0.5rem;
  }
  .modal_gameInfo.width55 {
    width: 100%;
  }
  .game_property {
    flex-direction: column;
  }
  .kind {
    margin-bottom: 5px;
    margin-right: 0px;
  }
  .game_kor {
    height: 4rem;
  }
  .modal_gameInfo .kind {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .kind {
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .modal_gameInfo .d-flex {
    flex-wrap: wrap;
  }
  .kind {
    margin-bottom: 5px !important;
    margin-left: 0px !important;
  }
}
</style>
