<template>
  <transition name="modal">
    <div class="modal-mask" @mousedown="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container" id="gameModal" @mousedown.stop>
          <div class="modal-header">
            <slot name="header">
              {{ item.game_kind }}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="text-left fontS">
                {{ item.text }}
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div
                style="border-top: 1px solid lightgray;"
                class="text-left paddingTB1"
              >
                <span class="font20 fontMedium">게임 스크린샷</span>
              </div>
              <div>
                <slot name="screenshot"> </slot>
              </div>
              <div
                v-show="check_screenWidth()"
                class="modal-default-button"
                @click="$emit('close')"
              >
                <img src="../../assets/source/images/ic_close.png" />
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {item: Object},
  mounted() {
    window.addEventListener("resize", this.check_screenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.check_screenWidth);
  },
  methods: {
    check_screenWidth() {
      return screen.width >= 767;
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 30vw;
  max-height: 70vh;
  margin: 0px auto;
  padding: 60px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: scroll;
}

.modal-body {
  padding-bottom: 50px;
  margin-top: 36px;
}

.modal-default-button {
  position: fixed;
  /* top: 10vh;
  left: 65vw; */
  top: 10px;
  right: 10px;
  /* background-color: #9a9a9a; */
  padding: 0.2rem;
  width: 60px;
  height: 60px;
}
.modal-default-button img {
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.modal-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
/* .modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .modal-body {
    margin-top: 20px;
    padding-bottom: 30px;
  }
  .modal-container {
    width: 50%;
  }
  .modal-mask {
    height: 100vh;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .modal-container {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .modal-mask {
    height: 100vh;
  }

  .modal-container {
    width: 50%;
  }
  .modal-body {
    margin-top: 18px;
    padding-bottom: 30px;
  }
  .screenshotWrap:first-child {
    margin: 1rem 0;
  }
  .paddingTB1 {
    padding: 0.5rem 0;
  }
}
@media (max-width: 499px) {
  .modal-container {
    width: 50%;
    padding: 30px;
  }
}
@media (max-height: 700px) {
  .modal-container {
    max-height: 50vh;
  }
  .modal-mask {
    height: 120%;
  }
}
@media (max-height: 500px) {
  .modal-container {
    max-height: 30vh;
  }
}
</style>
